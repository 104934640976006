<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-12">
            <b-card title="Options List">
              <div class="d-flex justify-content-end">
                <BFormInput
                  class="mb-2"
                  type="search"
                  placeholder="Search..."
                  style="width: 50%"
                  v-model="search.search_data"
                  @keyup.enter="getResults()"
                />
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">Sl</th>
                        <th class="text-center">Icon</th>
                        <th width="30%">Name</th>
                        <th>Category</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(option, index) in options.data"
                        :key="index"
                        v-if="options.data.length"
                      >
                        <td class="align-middle text-center">
                          {{ options.from + index }}
                        </td>
                        <td class="align-middle text-center">
                          <img
                            height="40"
                            :src="$store.state.base_url + option.image"
                            alt=""
                          />
                        </td>
                        <td><b></b>{{ option.name }}</td>
                        <td>
                          <span v-if="option.category">{{
                            option.category.name_bn
                          }}</span>
                        </td>
                        <td class="align-middle text-center">
                          <span
                            class="badge"
                            :class="
                              option.status == 1
                                ? 'badge-success'
                                : 'badge-danger'
                            "
                          >
                            {{ option.status == 1 ? "Active" : "Inactive" }}
                          </span>
                        </td>
                        <td class="align-middle text-center">
                          <button
                            class="btn btn-primary btn-sm"
                            @click="edit(option.id)"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination
                    :data="options"
                    @pagination-change-page="getResults"
                    align="center"
                    :show-disabled="true"
                    :limit="5"
                  ></pagination>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <b-card :title="form.id == '' ? 'Create' : 'Update'">
          <b-card-text>
            <form
              @submit.prevent="form.id ? update(form.id) : add()"
              @keydown="form.onKeydown($event)"
            >
              <div class="form-group">
                <label for="option_category_id">Select Option Category</label>
                <Select
                  v-model="form.option_category_id"
                  filterable
                  :class="{
                    'is-invalid border-danger':
                      form.errors.has('option_category_id'),
                  }"
                  name="option_category_id"
                  id="option_category_id"
                  placeholder="Select Option Category"
                >
                  <Option
                    :value="1"
                    v-for="(category, index) in categories"
                    v-if="categories.length"
                    :key="index"
                    >{{ category.name_en }}({{ category.name_bn }})</Option
                  >
                </Select>
                <has-error :form="form" field="option_category_id"></has-error>
              </div>
              <div class="form-group">
                <label for="name">Name</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('name'),
                  }"
                  name="name"
                  type="text"
                  v-model="form.name"
                  id="name"
                  placeholder="Enter Name"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('description'),
                  }"
                  name="description"
                  type="textarea"
                  :rows="5"
                  v-model="form.description"
                  id="description"
                  placeholder="Enter Description"
                />
                <has-error :form="form" field="description"></has-error>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="border rounded p-2 mb-2">
                    <b-media
                      no-body
                      vertical-align="center"
                      class="flex-column flex-md-row"
                    >
                      <b-media-aside>
                        <b-img
                          :src="icon_image"
                          height="50"
                          width="50"
                          class="rounded mr-2 mb-1 mb-md-0"
                        />
                      </b-media-aside>
                      <b-media-body>
                        <b-form-file
                          id="icon_image"
                          accept=".jpg, .png, .gif"
                          @change="loadImage($event)"
                          placeholder="Select Icon Image"
                          drop-placeholder="Drop file here..."
                        />
                      </b-media-body>
                    </b-media>
                  </div>
                </div>
              </div>
              <!-- submit buttons -->
              <b-form-checkbox
                name="status"
                style="margin-top: 5px"
                v-model="form.status"
                switch
                inline
              >
              </b-form-checkbox>
              <b-button type="submit" class="float-right" variant="primary">
                {{ form.id == "" ? "Create" : "Update" }}
              </b-button>
              <b-button
                type="button"
                class="float-right mx-1"
                variant="outline-primary"
                @click="Close"
              >
                Close
              </b-button>
            </form>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        _method: "get",
        page: 1,
      },
      form: new Form({
        id: "",
        option_category_id: null,
        name: null,
        description: null,
        status: true,
        icon_image: null,
      }),
      icon_image: this.getImage(100, 100),
      options: {},
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getOptionCategory");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const options = await this.callApi(
        "post",
        "/app/cms/option?page=" + this.search.page,
        this.search
      );
      if (options.status == 200) {
        this.options = options.data.options;
      }
    },

    loadImage(e) {
      this.form.icon_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.icon_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.name = null;
      this.form.option_category_id = null;
      this.form.description = null;
      this.form.status = true;
      this.form.icon_image = this.getImage(100, 100);
      this.icon_image = null;
    },

    async edit(id) {
      const option = await this.callApi("get", "/app/cms/option/" + id);
      if (option.status == 200) {
        this.form.id = option.data.option.id;
        this.form.name = option.data.option.name;
        this.form.option_category_id = option.data.option.option_category_id;
        this.form.description = option.data.option.description;
        this.form.status = option.data.option.status == true ? true : false;
        this.icon_image = option.data.option.image
          ? this.$store.state.base_url + option.data?.option?.image
          : this.getImage(100, 100);
      }
    },

    add() {
      this.form
        .post("/app/cms/option")
        .then(({ data }) => {
          this.getResults();
          this.Close();
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
    update(id) {
      this.form
        .post("/app/cms/option/" + id)
        .then(({ data }) => {
          this.getResults();
          this.Close();
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
  computed: {
    categories() {
      return this.$store.getters.getOptionCategory;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
